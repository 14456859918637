import { firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDApiClient } from '@do/app-base-components';
import {
  AutobindCommand,
  CloseCommand,
  MapRequestCommand,
  MasterControlUnitDto,
  OpenCommand,
  PairingCommand,
  ReadFullStatusCommand,
  RebootCommand,
  SetHeatingCommand,
  SetLightsCommand,
  SetODSCommand,
  SetStrokeCommand,
  StopCommand,
  TestCommand,
  UnbindCommand,
  UpdateMasterFirmwareCommand,
  UpdateModuleFirmwareCommand,
} from '@do/common-dto';

import { ApiClientConfig } from '../api-client-config';

@Injectable({ providedIn: 'root' })
export class MasterControlUnitApiClient extends BaseCRUDApiClient<MasterControlUnitDto> {
  baseUrl = this.config.apiEndpoint + '/master-control-unit';

  constructor(http: HttpClient, private config: ApiClientConfig) {
    super(http);
  }

  openCommand(masterControlUnitId: string, payload: OpenCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/open-command`,
        payload
      )
    );
  }

  closeCommand(masterControlUnitId: string, payload: CloseCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/close-command`,
        payload
      )
    );
  }

  stopCommand(masterControlUnitId: string, payload: StopCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/stop-command`,
        payload
      )
    );
  }

  testCommand(masterControlUnitId: string, payload: TestCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/test-command`,
        payload
      )
    );
  }

  rebootCommand(masterControlUnitId: string, payload: RebootCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/reboot-command`,
        payload
      )
    );
  }

  rebootMasterCommand(masterControlUnitId: string) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/reboot-master-command`,
        {}
      )
    );
  }

  readFullStatusCommand(
    masterControlUnitId: string,
    payload: ReadFullStatusCommand
  ) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/read-full-status-command`,
        payload
      )
    );
  }

  autobindCommand(masterControlUnitId: string, payload: AutobindCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/autobind-command`,
        payload
      )
    );
  }

  pairingCommand(masterControlUnitId: string, payload: PairingCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/pairing-command`,
        payload
      )
    );
  }

  setBridgeCommand(masterControlUnitId: string, payload: PairingCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/set-bridge-command`,
        payload
      )
    );
  }

  setStrokeCommand(masterControlUnitId: string, payload: SetStrokeCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/stroke-command`,
        payload
      )
    );
  }

  setHeatingCommand(masterControlUnitId: string, payload: SetHeatingCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/heating-command`,
        payload
      )
    );
  }

  setODSCommand(masterControlUnitId: string, payload: SetODSCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/ods-command`,
        payload
      )
    );
  }

  setLightsCommand(masterControlUnitId: string, payload: SetLightsCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/lights-command`,
        payload
      )
    );
  }

  unbindCommand(masterControlUnitId: string, payload: UnbindCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/unbind-command`,
        payload
      )
    );
  }

  updateSeatFirmware(
    masterControlUnitId: string,
    payload: UpdateModuleFirmwareCommand
  ) {
    const fd = new FormData();
    fd.set('file', payload.file);
    fd.set('force', payload.force ? 'true' : 'false');
    fd.set('firmwareModule', payload.firmwareModule.toString());
    payload.seats?.forEach((seat) => {
      fd.append('seats[]', seat.toString());
    });
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/update-seat-firmware`,
        fd
      )
    );
  }

  uploadMasterFirmware(payload: UpdateMasterFirmwareCommand) {
    const fd = new FormData();
    fd.set('file', payload.file);
    return firstValueFrom(
      this.http.post<void>(`${this.baseUrl}/upload-master-firmware`, fd)
    );
  }

  updateMasterFirmware() {
    return firstValueFrom(
      this.http.post<void>(`${this.baseUrl}/update-master-firmware`, {})
    );
  }

  reset(macAddress: string) {
    return firstValueFrom(
      this.http.post<MasterControlUnitDto>(`${this.baseUrl}/reset`, {
        macAddress,
      })
    );
  }

  getLatestFirmwareVersion() {
    return firstValueFrom(
      this.http.get<{ latestFirmwareVersion: string | null }>(
        `${this.baseUrl}/latest-firmware-version`
      )
    );
  }

  // mapping
  mapExitCommand(masterControlUnitId: string) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/map-exit-command`,
        {}
      )
    );
  }

  mapStartCommand(masterControlUnitId: string) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/map-start-command`,
        {}
      )
    );
  }

  mapRequestCommand(masterControlUnitId: string, payload: MapRequestCommand) {
    return firstValueFrom(
      this.http.post<void>(
        `${this.baseUrl}/${masterControlUnitId}/map-request-command`,
        payload
      )
    );
  }
}
